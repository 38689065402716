import React from 'react';
import { APIProvider } from '@deriv/api';
import { ModalProvider } from './components/ModalProvider';
import { BrowserRouter as Router } from 'react-router-dom';
import AppContent from './AppContent';
import Header from './components/Base/Header/Header';
import './styles/fonts.scss';
import './index.scss';

const App: React.FC = () => {
    return (
        <APIProvider standalone>
            <Header />
            <Router>
                <ModalProvider>
                    <AppContent />
                </ModalProvider>
            </Router>
        </APIProvider>
    );
};

export default App;
