import React, { FC } from 'react';
import './Header.scss';

const Header: FC<unknown> = () => {
    return (
        <div className='wallets-header'>
            <h1 style={{ textAlign: 'center', fontSize: 20 }}>HELLOOO LOL I AM HEADER HEHE</h1>
        </div>
    );
};

export default Header;
