import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { CashierModalRoute } from './CashierModalRoute';
import { WalletsListingRoute } from './WalletsListingRoute';

type TRoutes =
    | '/cashier/deposit'
    | '/cashier/transactions'
    | '/cashier/transfer'
    | '/cashier/withdraw'
    | '/cashier'
    | '/';

declare module 'react-router-dom' {
    export function useHistory(): { push: (path: TRoutes) => void };

    export function useRouteMatch(path: TRoutes): boolean;
}

const Router: React.FC = () => (
    <Switch>
        <Route component={CashierModalRoute} path={`/cashier`} />
        <Route component={WalletsListingRoute} path={'/'} />
    </Switch>
);

export default Router;
