import React, { useEffect } from 'react';
import { Router } from './routes';
import { useAuthorize } from '@deriv/api';
import { redirectToLogin } from '@deriv/shared';
import './AppContent.scss';
import { getLanguage } from '@deriv/translations';

const AppContent: React.FC = () => {
    const { isError } = useAuthorize();

    useEffect(() => {
        redirectToLogin(false, getLanguage());
    }, [isError]);

    return (
        <div className='wallets-app'>
            <Router />
        </div>
    );
};

export default AppContent;
